exports.components = {
  "component---src-pages-4-x-4-tours-tsx": () => import("./../../../src/pages/4x4-tours.tsx" /* webpackChunkName: "component---src-pages-4-x-4-tours-tsx" */),
  "component---src-pages-academy-tsx": () => import("./../../../src/pages/academy.tsx" /* webpackChunkName: "component---src-pages-academy-tsx" */),
  "component---src-pages-enduro-tours-tsx": () => import("./../../../src/pages/enduro-tours.tsx" /* webpackChunkName: "component---src-pages-enduro-tours-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mountain-bikes-tsx": () => import("./../../../src/pages/mountain-bikes.tsx" /* webpackChunkName: "component---src-pages-mountain-bikes-tsx" */),
  "component---src-pages-trekking-tsx": () => import("./../../../src/pages/trekking.tsx" /* webpackChunkName: "component---src-pages-trekking-tsx" */),
  "component---src-templates-photos-tsx": () => import("./../../../src/templates/photos.tsx" /* webpackChunkName: "component---src-templates-photos-tsx" */)
}

